import dynamic from 'next/dynamic'
import { forwardRef, memo, useMemo, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'

const Price = dynamic(() => import('../price'))
const Rating = dynamic(() => import('../rating'))
const AddCartButton = dynamic(() => import('@components/buttons/add_cart_button'))
const Variants = dynamic(() => import('@components/pages/products/product/variants'))
const ErrorBoundary = dynamic(() => import('../error_boundary'))
const Checkbox = dynamic(() => import('../checkbox'))
const HeartIcon = dynamic(() => import('../heart_icon'))

import useWishlist from '@functions/hooks/useWishlist'
import { useImageFormatErrorHandler } from '@functions/hooks'
import { isMobile } from '@utils'
import { clx, analytics } from '@utils'
import s from './product_item.module.scss'
import { logEvent } from 'firebase/analytics'

const ProductItem = ({
   id,
   src,
   title,
   goodCode,
   ignoreMinimum,
   rating = 0,
   reviews = [],
   minimum,
   price,
   discountPrice,
   quantity,
   alternativeQuantity = [],
   isSold,
   groups: variants,
   discountPercent,

   withWishlistButton,
   withWishlistCheckbox,
   onSelect,
   isSelected,
   className,

   withoutButton,
   onAddGoodToCart,
   product
}, ref) => {
   const { addGoodToWishlist, isFavorite } = useWishlist()
   const [isShowVariants, setIsShowVariants] = useState(false)
   const [imgSrc, onImageError] = useImageFormatErrorHandler(src)

   const firebaseProps = {
      currency: 'UAH',
      value: discountPrice ? discountPrice : price,
      content_name: title,
      content_type: 'product',
      content_ids: [id],
   }

   /* TODO: add check to mobile. disable variants on hover if is mobile */
   const isAllowedVariants = useMemo(
      () => Boolean(variants.length && !isMobile),
      [variants, isMobile]
   )

   const handleHover = event => {
      if (isAllowedVariants) {
         setIsShowVariants(true)
      }
   }

   const handleMouseLeave = event => {
      if (isAllowedVariants) {
         setIsShowVariants(false)
      }
   }

   const handleAddToWishlist = () => {
      addGoodToWishlist(goodCode)
   }

   const handleCheckboxChange = isChecked => {
      onSelect(isChecked, goodCode)
   }

   const handleSendAnlitics = () => {
      const analyticsInstance = analytics()
      logEvent(analyticsInstance, 'details_about_good', firebaseProps)//firebaseAnalitics
   }

   return (
      <ErrorBoundary empty>
         <div className={ s.goodCardWrapper }>
            <div
               ref={ ref }
               className={ clx(s.product_item, className, isShowVariants && s.card_with_variants) }
               onMouseEnter={ handleHover }
               onMouseLeave={ handleMouseLeave }
            >
               <div className={ s.product_item_info }>
                  {
                     discountPrice && +discountPrice < +price
                        ? <div className={ `${s.special_percent} ${isSold ? s.not_avalible : ''}` }>{`Ням -${discountPercent || ''}%`}</div>
                        : null
                  }
                  {isSold ? <div className={ s.sold }>Продано!</div> : null}
               </div>

               {withWishlistButton && (
                  <HeartIcon
                     onClick={ handleAddToWishlist }
                     isActive={ isFavorite(goodCode) }
                     className={ s.favorite_icon }
                  />
               )}

               {withWishlistCheckbox && (
                  <Checkbox
                     id={ goodCode }
                     onChange={ handleCheckboxChange }
                     checked={ isSelected(goodCode) }
                     className={ s.checkbox }
                     width={ 20 }
                     height={ 20 }
                  />
               )}

               <Link href={ `/products/${goodCode}` } >
                  <a className={ s.good_link } onClick={ handleSendAnlitics }>
                     <div className={ s.good_preview }>
                        <div className={ `${s.good_preview_inner} ${isSold ? s.not_avalible : ''}` }>
                           <Image
                              src={ imgSrc || src }
                              width={ 177 }
                              height={ 177 }
                              alt={ title + ' фото' }
                              title={ title + ' фото' }
                              onError={ onImageError }
                              loading="lazy"
                           />
                        </div>
                     </div>
                     <span className={ `${s.good_title} ${isSold ? s.not_avalible : ''}` }>{title}</span>
                  </a>
               </Link>
               <div className={ s.goods_info }>
                  <Rating ratingNumber={ rating } productCode={ goodCode } reviewsCount={ reviews.length } />
                  <div className={ s.code }>
                     Код: {goodCode}
                  </div>
               </div>
               <div className={ s.goods_buy }>
                  <div className={ s.price }>
                     <Price price={ price } discountPrice={ discountPrice } />
                     {withWishlistCheckbox &&
                        <AddCartButton
                           quantity={ quantity }
                           title={ title }
                           productCode={ goodCode }
                           ignoreMinimum={ ignoreMinimum }
                           minimum={ minimum }
                           minimumOrderQuantity={ minimum }
                           hasAlternativeQty={ alternativeQuantity.length > 0 }
                           onClick={ onAddGoodToCart }
                           isFavorite={ isFavorite(goodCode) }
                           product={ product }
                           className={ s.whishlist_buy_button }
                           withWishlistCheckbox={ withWishlistCheckbox }
                        />
                     }
                  </div>


               </div>

               {isAllowedVariants &&
                  <div className={ clx(s.variants_list, isShowVariants && s.variants_list_open) }>
                     <Variants variants={ variants } id={ id } small currentProductCode={ goodCode } />
                  </div>
               }

            </div>
            {!withoutButton && (
               <div className={ s.buy_btn }>
                  <AddCartButton
                     quantity={ quantity }
                     title={ title }
                     productCode={ goodCode }
                     ignoreMinimum={ ignoreMinimum }
                     minimum={ minimum }
                     minimumOrderQuantity={ minimum }
                     hasAlternativeQty={ alternativeQuantity.length > 0 }
                     // onClick={ handleAddToWishlist }
                     onClick={ onAddGoodToCart }
                     isFavorite={ isFavorite(goodCode) }
                     product={ product }
                  />
               </div>
            )}
         </div>
      </ErrorBoundary>
   )
}

export default memo(forwardRef(ProductItem))
